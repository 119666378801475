import React from "react"
import { RangeInputWrapper } from "./RangeInput.styles"

type RangeInputProps = {
  value: number
  min: number
  max: number
  handleChange: (value: number) => void
}

export const RangeInput = ({
  value,
  min,
  max,
  handleChange,
}: RangeInputProps) => (
  <RangeInputWrapper
    type="range"
    value={value}
    min={min}
    max={max}
    progress={((value - min) / (max - min)) * 100}
    onChange={e => handleChange(parseInt(e.target.value))}
  />
)
