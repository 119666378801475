import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { RootState } from "redux/rootReducer"
import { letterBoardFelts } from "./LetterBoard.constants"

import { StyledCanvas } from "./LetterBoardCanvas.styles"

export const BackgroundCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>()
  const { canvasDimensions, color } = useSelector(
    (state: RootState) => state.letterBoardDesignTool
  )

  useEffect(() => {
    canvasRef.current.width = canvasDimensions.displayWidth
    canvasRef.current.height = canvasDimensions.displayHeight
    const context = canvasRef.current.getContext("2d")

    draw({ context })
  }, [canvasDimensions, color])

  const draw = ({ context }: { context: CanvasRenderingContext2D }) => {
    const feltImage = document.createElement("img")
    feltImage.src = letterBoardFelts[color.name]
    feltImage.onload = () => {
      const aspect = feltImage.naturalWidth / feltImage.naturalHeight
      //felt images are defined to be 4in high
      const height = canvasDimensions.lineHeight * 16
      const width = height / aspect
      let flip: boolean
      let roundedX: number
      let roundedY: number
      let roundedWidth: number
      let roundedHeight: number

      for (let y = 0; y < canvasDimensions.fullHeight; y += height) {
        flip = false

        for (let x = 0; x < canvasDimensions.fullWidth; x += width) {
          roundedX = Math.floor(x)
          roundedY = Math.floor(y)
          roundedWidth = Math.ceil(width)
          roundedHeight = Math.ceil(height)
          if (flip) {
            context.translate(roundedWidth + roundedX, 0)
            context.scale(-1, 1)
            context.translate(-roundedX, 0)
          }
          context.drawImage(
            feltImage,
            roundedX,
            roundedY,
            roundedWidth,
            roundedHeight
          )
          context.setTransform(1, 0, 0, 1, 0, 0)
          flip = !flip
        }
      }
      context.setTransform(1, 0, 0, 1, 0, 0)
      context.drawImage(
        context.canvas,
        0,
        0,
        canvasDimensions.displayWidth,
        canvasDimensions.displayHeight
      )
    }
  }

  return (
    <StyledCanvas
      aria-label="Letter Board Design Tool Background Canvas"
      ref={canvasRef}
    />
  )
}
