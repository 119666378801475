import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { RootState } from "redux/rootReducer"
import { SanityDefinedColor } from "typings/graphql"
import { StyledCanvas } from "./LetterBoardCanvas.styles"

import LetterfolkBold from "../../assets/fonts/Letterfolk_Three_Quarters-Bold.woff"
import LetterfolkLight from "../../assets/fonts/Letterfolk_Three_Quarters-Light.woff"
import LetterfolkRegular from "../../assets/fonts/Letterfolk_Three_Quarters-Regular.woff"
import LetterfolkShade from "../../assets/fonts/Letterfolk_Three_Quarters-Shade.woff"

export const TextCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>()
  const {
    color,
    letterSize,
    userText,
    textAlignment,
    lineSpacing,
    canvasDimensions,
  } = useSelector((state: RootState) => state.letterBoardDesignTool)
  const loadFonts = async () => {
    const fonts = await Promise.all([
      new FontFace("Letterfolk Bold", `url(${LetterfolkBold})`).load(),
      new FontFace("Letterfolk Light", `url(${LetterfolkLight})`).load(),
      new FontFace("Letterfolk Regular", `url(${LetterfolkRegular})`).load(),
      new FontFace("Letterfolk Shade", `url(${LetterfolkShade})`).load(),
    ])
    fonts.forEach(font => canvasRef.current.ownerDocument.fonts.add(font))
  }

  useEffect(() => {
    loadFonts()
  }, [])

  useEffect(() => {
    canvasRef.current.width = canvasDimensions.displayWidth
    canvasRef.current.height = canvasDimensions.displayHeight
    const context = canvasRef.current.getContext("2d")

    draw({ context })
  }, [
    canvasRef,
    canvasDimensions,
    userText,
    letterSize,
    textAlignment,
    lineSpacing,
    color,
  ])

  const roundToLine = (y: number, lineHeight: number) =>
    Math.floor(Math.round(y / lineHeight) * lineHeight)

  const draw = ({ context }: { context: CanvasRenderingContext2D }) => {
    const { lineHeight, fullWidth, fullHeight, frameSize } = canvasDimensions

    const letterSizeMultiplier = parseInt(letterSize.value)
    const size = letterSizeMultiplier * lineHeight * 1.3
    const originalLines = userText.split("\n")
    const trimmedLines: string[] = []

    //manual linebreaking
    context.font = size + 'px/1 "Letterfolk Bold"'
    const wrapWidth = fullWidth - frameSize * 2 - lineHeight * 2
    let longestLine = 0

    originalLines.forEach(line => {
      const trimmedLine = line.trim()
      const width = context.measureText(trimmedLine).width
      let words: string[]
      let testLine: string[]

      if (width <= wrapWidth) {
        trimmedLines.push(trimmedLine)
        longestLine = Math.max(longestLine, width)
      } else {
        words = trimmedLine.split(/\s+/)
        testLine = []
        let lineWidth = 0

        words.forEach(word => {
          testLine.push(word)
          const width = context.measureText(testLine.join(" ")).width

          if (width > wrapWidth) {
            testLine.pop()

            if (testLine.length) {
              trimmedLines.push(testLine.join(" "))
              testLine = [word]
              longestLine = Math.max(longestLine, lineWidth)
              lineWidth = context.measureText(word).width
            } else {
              trimmedLines.push(word)
              longestLine = wrapWidth
              lineWidth = 0
            }
          } else {
            lineWidth = width
          }
        })
        if (testLine.length) {
          trimmedLines.push(testLine.join(" "))
          longestLine = Math.max(longestLine, lineWidth)
        }
      }
    })

    const numLines = trimmedLines.length
    let x: number
    let y: number

    switch (textAlignment) {
      case "left":
        context.textAlign = "left"
        x = Math.round(Math.min(fullWidth / 4, (fullWidth - longestLine) / 2))
        break

      case "right":
        context.textAlign = "right"
        x =
          fullWidth -
          Math.round(Math.min(fullWidth / 4, (fullWidth - longestLine) / 2))
        break

      default:
        context.textAlign = "center"
        x = fullWidth / 2
        break
    }

    y =
      (fullHeight +
        letterSizeMultiplier * lineHeight -
        (numLines - 1) * (letterSizeMultiplier + lineSpacing) * lineHeight) /
      2

    trimmedLines.forEach(textLine => {
      const fontColor = color.swatchOrOpacityHex[2] as SanityDefinedColor
      const roundedY = roundToLine(y, lineHeight)

      context.font = `${size}px/1 Letterfolk Bold`
      context.fillStyle = fontColor.opacityHex === "white" ? "#444" : "#fff"
      context.fillText(textLine, x, roundedY)

      context.font = `${size}px/1 Letterfolk Shade`
      context.fillStyle = fontColor.opacityHex === "white" ? "#000" : "#ddd"
      context.fillText(textLine, x, roundedY)

      context.font = `${size}px/1 Letterfolk ${
        letterSizeMultiplier === 8 ? "Regular" : "Light"
      }`
      context.fillStyle = fontColor.opacityHex === "white" ? "#222" : "#eee"
      context.fillText(textLine, x, roundedY)

      y += (letterSizeMultiplier + lineSpacing) * lineHeight
    })
  }

  return (
    <StyledCanvas
      aria-label="Letter Board Design Tool Background Canvas"
      ref={canvasRef}
    />
  )
}
