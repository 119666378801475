import styled from "styled-components"

const ALIGNMENT = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
}

export const AlignmentSelectorWrapper = styled.div`
  display: flex;
  height: 22px;
  margin-bottom: 8px;
  align-items: center;
`

export const Line = styled.div<{ short?: boolean; active: boolean }>`
  height: 1px;
  width: ${({ short = false }) => (short ? "12px" : "18px")};
  transition: background-color 0.3s ease;
  background: ${({ theme, active }) =>
    active
      ? theme.dark.ui?.icon?.opacityHex
      : theme.dark.ui?.iconInactive?.opacityHex};

  &:not(:first-child) {
    margin-top: 2px;
  }
`

export const AlignmentWrapper = styled.button<{
  align: "left" | "center" | "right"
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align = "center" }) => ALIGNMENT[align]};
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;

  &:not(:first-child) {
    margin-left: 20px;
  }

  &:hover {
    ${Line} {
      background: ${({ theme }) => theme.dark.ui?.iconHover?.opacityHex};
    }
  }
`
