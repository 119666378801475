import React, { useEffect, useRef } from "react"
import { useWindowSize } from "react-use"
import { useDispatch, useSelector } from "react-redux"
import { initialize } from "redux/letterBoard"
import { CanvasWrapper } from "./LetterBoardCanvas.styles"

import { BackgroundCanvas } from "./BackgroundCanvas"
import { TextCanvas } from "./TextCanvas"
import { FrameCanvas } from "./FrameCanvas"
import { RootState } from "redux/rootReducer"
import { TitleBlock, TitleBlockProps } from "components/DesignTools/TitleBlock"
import {
  DesignToolCanvasWrapper,
  DesignToolTitleWrapper,
} from "components/DesignTools/DesignToolsStyles"

export const LetterBoardCanvas: React.FC<TitleBlockProps> = ({
  title,
  subTitle,
}) => {
  const dispatch = useDispatch()
  const windowSize = useWindowSize()
  const canvasContainerRef = useRef<HTMLDivElement>()

  const { letterBoardSize, canvasDimensions } = useSelector(
    (state: RootState) => state.letterBoardDesignTool
  )

  useEffect(() => {
    dispatch(
      initialize({ containerWidth: canvasContainerRef.current?.offsetWidth })
    )
  }, [canvasContainerRef, windowSize, letterBoardSize])

  return (
    <DesignToolCanvasWrapper>
      <DesignToolTitleWrapper>
        <TitleBlock title={title} subTitle={subTitle} />
      </DesignToolTitleWrapper>
      <CanvasWrapper
        ref={canvasContainerRef}
        containerHeight={canvasDimensions?.displayHeight || 50}
      >
        {canvasDimensions && (
          <>
            <BackgroundCanvas />
            <TextCanvas />
            <FrameCanvas />
          </>
        )}
      </CanvasWrapper>
    </DesignToolCanvasWrapper>
  )
}
