import styled from "styled-components"

export const TextArea = styled.textarea`
  width: 100%;
  border: 0;
  background-color: ${({ theme }) => theme.dark?.ui?.formField?.opacityHex};
  color: ${({ theme }) => theme.dark?.text?.formActive?.opacityHex};
  padding: 14px 16px;
  font-size: 14px;
  &::placeholder {
    color: ${({ theme }) => theme?.dark?.text?.formInactive?.opacityHex};
  }
`

export const TextAlignmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TextAlignmentRow = styled.div`
  display: flex;
  column-gap: 16px;
`

export const TextAlignmentColumn = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
`

export const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 17px;
  width: 100%;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};
`

export const Capitalized = styled.span`
  &::first-letter {
    text-transform: capitalize;
  }
`
