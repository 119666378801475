import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { RootState } from "redux/rootReducer"
import { StyledCanvas } from "./LetterBoardCanvas.styles"
import { letterBoardFrames } from "./LetterBoard.constants"

export const FrameCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>()
  const { canvasDimensions, color } = useSelector(
    (state: RootState) => state.letterBoardDesignTool
  )

  useEffect(() => {
    canvasRef.current.width = canvasDimensions.displayWidth
    canvasRef.current.height = canvasDimensions.displayHeight
    const context = canvasRef.current.getContext("2d")

    draw({ context })
  }, [canvasDimensions, color])

  const draw = ({ context }: { context: CanvasRenderingContext2D }) => {
    const frameImage = document.createElement("img")
    frameImage.src = letterBoardFrames[color.name]
    frameImage.onload = () => {
      //shadow
      context.save()
      context.shadowOffsetY = canvasDimensions.lineHeight * 0.25
      context.shadowOffsetX = -context.shadowOffsetY
      context.shadowColor = "rgba(0, 0, 0, 0.4)"
      context.shadowBlur = 30
      context.moveTo(0, 0)
      context.beginPath()
      context.lineTo(canvasDimensions.fullWidth, 0)
      context.lineTo(canvasDimensions.fullWidth, canvasDimensions.fullHeight)
      context.lineTo(
        canvasDimensions.fullWidth - canvasDimensions.frameSize,
        canvasDimensions.fullHeight
      )
      context.lineTo(
        canvasDimensions.fullWidth - canvasDimensions.frameSize,
        canvasDimensions.frameSize
      )
      context.lineTo(0, canvasDimensions.frameSize)
      context.lineTo(0, 0)
      context.fill()
      context.restore()

      const aspect = frameImage.naturalWidth / frameImage.naturalHeight
      const width = canvasDimensions.frameSize
      const height = width / aspect
      const roundedWidth = Math.ceil(width)
      const roundedHeight = Math.ceil(height)
      const maxWidth = Math.max(
        canvasDimensions.fullHeight,
        canvasDimensions.fullWidth
      )

      let flip = false

      for (let y = 0; y < maxWidth; y += height) {
        const roundedY = Math.floor(y)

        // left
        context.translate(0, roundedY)
        if (flip) {
          context.translate(0, roundedHeight)
          context.scale(1, -1)
        }
        context.drawImage(frameImage, 0, 0, roundedWidth, roundedHeight)
        context.setTransform(1, 0, 0, 1, 0, 0)

        //right
        context.translate(
          canvasDimensions.fullWidth,
          canvasDimensions.fullHeight
        )
        context.rotate(Math.PI)
        context.translate(0, roundedY)
        if (flip) {
          context.translate(0, roundedHeight)
          context.scale(1, -1)
        }
        context.drawImage(frameImage, 0, 0, roundedWidth, roundedHeight)
        context.setTransform(1, 0, 0, 1, 0, 0)

        //top
        context.translate(canvasDimensions.fullWidth, 0)
        context.rotate(Math.PI / 2)
        context.translate(0, roundedY)
        if (flip) {
          context.translate(0, roundedHeight)
          context.scale(1, -1)
        }
        context.drawImage(frameImage, 0, 0, roundedWidth, roundedHeight)
        context.setTransform(1, 0, 0, 1, 0, 0)

        //bottom
        context.translate(0, canvasDimensions.fullHeight)
        context.rotate(-Math.PI / 2)
        context.translate(0, roundedY)
        if (flip) {
          context.translate(0, roundedHeight)
          context.scale(1, -1)
        }
        context.drawImage(frameImage, 0, 0, roundedWidth, roundedHeight)
        context.setTransform(1, 0, 0, 1, 0, 0)

        flip = !flip
      }

      //do corners
      const render = () => {
        context.save()
        context.moveTo(0, 0)
        context.beginPath()
        context.lineTo(canvasDimensions.frameSize, canvasDimensions.frameSize)
        context.lineTo(0, canvasDimensions.frameSize)
        context.lineTo(0, 0)
        context.clip()
        context.drawImage(frameImage, 0, 0, roundedWidth, roundedHeight)
        context.restore()
        context.setTransform(1, 0, 0, 1, 0, 0)
      }

      //top left
      render()

      //top right
      context.translate(canvasDimensions.fullWidth, 0)
      context.rotate(Math.PI / 2)
      render()

      //bottom right
      context.translate(canvasDimensions.fullWidth, canvasDimensions.fullHeight)
      context.rotate(Math.PI)
      render()

      //bottom left
      context.translate(0, canvasDimensions.fullHeight)
      context.rotate(-Math.PI / 2)
      render()
    }
  }
  return (
    <StyledCanvas
      aria-label="Letter Board Design Tool Frame Canvas"
      ref={canvasRef}
    />
  )
}
