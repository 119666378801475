import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "redux/rootReducer"

import {
  changeText,
  changeLetterBoardSize,
  changeLetterSize,
  changeLineSpacing,
  changeTextAlignment,
  changeColor,
} from "redux/letterBoard"

import { Dropdown } from "components/UI/Dropdown/Dropdown"
import { DropdownItem } from "components/UI/Dropdown/Dropdown.styles"
import { Label } from "components/UI/Label"
import { RadioInput } from "components/UI/RadioInput/RadioInput"

import {
  TextArea,
  TextAlignmentWrapper,
  Line,
  TextAlignmentRow,
  Capitalized,
  LineWrapper,
  TextAlignmentColumn,
} from "./LetterBoardRightRail.styles"
import {
  letterBoardColors,
  letterBoardTypes,
  letterSizes,
} from "./LetterBoard.constants"
import { VariantOptionWithId } from "typings/modules"
import { TextAlignmentSelector } from "./TextAlignment"
import { LetterBoardType, TextAlignmentType } from "./LetterBoard.types"
import { AccordionList } from "components/AccordionList/AccordionList"
import { SanityAccordionItem } from "typings/graphql"
import { AccordionItem } from "components/AccordionItem/AccordionItem"
import { DetailsAccordionProduct } from "components/Products/DetailsAccordionProduct"
import { ProductDetails } from "components/Products/ProductDetails"
import {
  ColorSelector,
  SelectColorOption,
} from "components/ColorOptions/ColorSelector"
import { ColorDescriptor } from "components/ColorOptions/ColorDescriptor"
import { TitleBlock, TitleBlockProps } from "components/DesignTools/TitleBlock"
import {
  RightRailTitleContainer,
  RightRailWrapper,
} from "components/DesignTools/RightRailStyles"
import { RangeInput } from "components/UI/RangeInput/RangeInput"

type LetterBoardRightRailProps = {
  accordionItems: SanityAccordionItem[]
} & TitleBlockProps

export const LetterBoardRightRail: React.FC<LetterBoardRightRailProps> = ({
  title,
  subTitle,
  accordionItems,
  ...props
}) => {
  const dispatch = useDispatch()
  const {
    letterBoardSize,
    letterSize,
    lineSpacing,
    textAlignment,
    userText,
    color,
  } = useSelector((state: RootState) => state.letterBoardDesignTool)

  const handleChangeText = (userText: string) => {
    dispatch(changeText({ userText }))
  }

  const handleChangeLetterSize = (letterSize: VariantOptionWithId) => {
    dispatch(changeLetterSize({ letterSize }))
  }

  const handleChangeLineSpacing = (lineSpacing: number) => {
    dispatch(changeLineSpacing({ lineSpacing }))
  }

  const handleChangeTextAlignement = (textAlignment: TextAlignmentType) => {
    dispatch(changeTextAlignment({ textAlignment }))
  }

  const handleChangeLetterBoardSize = (letterBoardSize: LetterBoardType) => {
    dispatch(changeLetterBoardSize({ letterBoardSize }))
  }

  const handleChangeColor = (color: SelectColorOption) => {
    dispatch(changeColor({ color }))
  }

  return (
    <RightRailWrapper>
      <RightRailTitleContainer>
        <TitleBlock title={title} subTitle={subTitle} />
      </RightRailTitleContainer>
      <TextArea
        id="user-text"
        placeholder="Write away..."
        aria-label="Letter Board Text"
        name="userText"
        value={userText}
        onChange={e => handleChangeText(e.target.value)}
      />
      <div>
        <Label>Letter Size</Label>
        <RadioInput
          options={letterSizes.map(size => ({ option: size }))}
          selected={letterSize}
          displayField="name"
          selectOption={letterSize => () => handleChangeLetterSize(letterSize)}
          optionName="Letter Size"
        />
      </div>
      <TextAlignmentWrapper>
        <TextAlignmentRow>
          <TextAlignmentColumn>
            <Label>Line spacing</Label>
          </TextAlignmentColumn>
          <TextAlignmentColumn>
            <TextAlignmentSelector
              align={textAlignment}
              handleChange={handleChangeTextAlignement}
            />
          </TextAlignmentColumn>
        </TextAlignmentRow>
        <TextAlignmentRow>
          <RangeInput
            value={lineSpacing}
            min={1}
            max={10}
            handleChange={handleChangeLineSpacing}
          />
          <LineWrapper>
            <Line />
          </LineWrapper>
        </TextAlignmentRow>
      </TextAlignmentWrapper>
      <div>
        <Dropdown
          position="bottom left"
          label="Letter Board Size"
          renderButton={<Capitalized>{letterBoardSize}</Capitalized>}
          renderList={letterBoardTypes.map(letterBoardSize => (
            <DropdownItem
              aria-label="Letter Board Size"
              key={letterBoardSize}
              onClick={() => handleChangeLetterBoardSize(letterBoardSize)}
            >
              <Capitalized>{letterBoardSize}</Capitalized>
            </DropdownItem>
          ))}
        />
      </div>
      <div>
        <ColorDescriptor label="Color" value={color.name} />
        <ColorSelector
          mode="dark"
          colors={letterBoardColors}
          selectedColorName={color.name}
          onColorSelected={selectedColor => handleChangeColor(selectedColor)}
        />
      </div>
      <AccordionList styleType="secondary">
        {accordionItems?.map(accordionItem => (
          <AccordionItem
            key={`tile-mat-accordion-item-${accordionItem.id}`}
            eventTrackingId={accordionItem.internalName}
            openByDefault={accordionItem.expanded}
            title={accordionItem.title}
            styleType="secondary"
          >
            {!!accordionItem.product ? (
              <DetailsAccordionProduct
                mode="dark"
                productItem={accordionItem.product}
              />
            ) : (
              <ProductDetails items={accordionItem._rawContent} />
            )}
          </AccordionItem>
        ))}
      </AccordionList>
    </RightRailWrapper>
  )
}
