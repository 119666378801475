import { DesignToolGrid } from "components/DesignTools/DesignToolsStyles"
import { TitleBlockProps } from "components/DesignTools/TitleBlock"
import React from "react"
import { SanityAccordionItem } from "typings/graphql"
import { LetterBoardCanvas } from "./LetterBoardCanvas"
import { LetterBoardRightRail } from "./LetterBoardRightRail"

type Props = {
  accordionItems: SanityAccordionItem[]
} & TitleBlockProps

export const LetterBoardDesignTool: React.FC<Props> = ({
  title,
  subTitle,
  accordionItems,
}) => {
  return (
    <DesignToolGrid>
      <LetterBoardCanvas title={title} subTitle={subTitle} />
      <LetterBoardRightRail
        title={title}
        subTitle={subTitle}
        accordionItems={accordionItems}
      />
    </DesignToolGrid>
  )
}
