import React from "react"
import { SanityLetterBoardDesignTool } from "typings/graphql"
import { LetterBoardDesignTool } from "./LetterBoardDesignTool"

export const SanityLetterBoardDesignToolBlock = ({
  fields,
}: {
  fields: SanityLetterBoardDesignTool
}) => (
  <LetterBoardDesignTool
    title={fields.title}
    subTitle={fields.subTitle}
    accordionItems={fields.accordionItems}
    {...fields}
  />
)

export default SanityLetterBoardDesignToolBlock
