import React from "react"
import { TextAlignmentType } from "./LetterBoard.types"
import {
  AlignmentSelectorWrapper,
  AlignmentWrapper,
  Line,
} from "./TextAlignment.styles"

type TextAlignProps = {
  active: boolean
  align: TextAlignmentType
  handleClick: () => void
}

const TextAlignment: React.FC<TextAlignProps> = ({
  active,
  align = "center",
  handleClick,
}) => {
  return (
    <AlignmentWrapper
      onClick={handleClick}
      align={align}
      aria-label={`Align ${align}`}
    >
      <Line active={active} />
      <Line active={active} short />
      <Line active={active} />
      <Line active={active} short />
    </AlignmentWrapper>
  )
}

type TextAlignmentSelectorProps = {
  align: TextAlignmentType
  handleChange: (alignment: TextAlignmentType) => void
}

export const TextAlignmentSelector: React.FC<TextAlignmentSelectorProps> = ({
  align,
  handleChange,
}) => {
  return (
    <AlignmentSelectorWrapper>
      <TextAlignment
        handleClick={() => handleChange("left")}
        active={align === "left"}
        align="left"
      />
      <TextAlignment
        handleClick={() => handleChange("center")}
        active={align === "center"}
        align="center"
        tab-index={1}
      />
      <TextAlignment
        handleClick={() => handleChange("right")}
        active={align === "right"}
        align="right"
        tab-index={1}
      />
    </AlignmentSelectorWrapper>
  )
}
