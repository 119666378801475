import styled from "styled-components"

export const CanvasWrapper = styled.div<{ containerHeight: number }>`
  position: relative;
  height: ${({ containerHeight }) => containerHeight}px;
`

export const StyledCanvas = styled.canvas`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
`
