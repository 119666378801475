import React from "react"
import styled from "styled-components"
import { typography } from "styles"

const Title = styled.h1`
  ${typography.h4};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  margin-bottom: 8px;
`

const SubTitle = styled.p`
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
`

export type TitleBlockProps = {
  title: string
  subTitle?: string
}

export const TitleBlock: React.FC<TitleBlockProps> = ({ title, subTitle }) => {
  return (
    <>
      <Title role="heading">{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </>
  )
}
