import styled, { css } from "styled-components"

const TrackStyles = css`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.dark.ui?.divider?.opacityHex};
`

export const RangeInputWrapper = styled.input<{ progress: number }>`
  appearance: none;
  width: 100%;
  height: 17px;
  background: transparent;
  cursor: pointer;
  margin: 0;
  overflow: hidden;

  &::-webkit-slider-thumb {
    appearance: none;
    height: 17px;
    width: 17px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.dark.ui?.icon?.opacityHex};
    margin-top: -8px;
  }

  &::-moz-range-thumb {
    border: none;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.dark.ui?.icon?.opacityHex};
    height: 17px;
    width: 17px;
  }

  &::-webkit-slider-runnable-track {
    ${TrackStyles}
    background-image: linear-gradient(
      ${({ theme }) => theme.dark.ui?.iconStroke?.opacityHex},
      ${({ theme }) => theme.dark.ui?.iconStroke?.opacityHex}
    );
    background-size: ${({ progress }) => progress}% 100%;
    background-repeat: no-repeat;
  }

  &::-moz-range-track {
    ${TrackStyles}
  }

  &&::-moz-range-progress {
    height: 1px;
    background-color: ${({ theme }) => theme.dark.ui?.iconStroke?.opacityHex};
  }

  &&::-moz-range-track {
    background-color: ${({ theme }) => theme.dark.ui?.divider?.opacityHex};
  }

  &&::-ms-fill-lower {
    background-color: ${({ theme }) => theme.dark.ui?.iconStroke?.opacityHex};
  }
  &&::-ms-fill-upper {
    background-color: ${({ theme }) => theme.dark.ui?.divider?.opacityHex};
  }
`
